import { useEffect, useRef, useState } from "react";
import { coinImg, coinWebpImg } from "../../../../assets/images";
import { IBooster } from "../../../../models/IBooster";
import CustomModal from "../../../layout/CustomModal/CustomModal";
import ImageWebp from "../../../layout/ImageWebp/ImageWebp";
import MainBtn from "../../../layout/MainBtn/MainBtn";
import ProgressBar from "../../../layout/ProgressBar/ProgressBar";

import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { upgradeBooster } from "../../../../store/slices/boostSlice";
import {
  updateUserCoins,
  updateUserCoinsPerHour,
} from "../../../../store/slices/profileSlice";
import { baseUrl } from "../../../../store/tools/fetchTools";
import CustomTooltip from "../../../layout/CustomTooltip/CustomTooltip";
import styles from "./BoostBuyModal.module.scss";

const tooltipTexts = {
  loading: "Upgrading",
  error: "Upgrade failed",
  success: "Upgraded",
};

type Props = {
  open: boolean;
  onClose: () => void;
  booster: IBooster | undefined;
};

const BoostBuyModal = ({ open, onClose, booster }: Props) => {
  const hapticFeedback = useHapticFeedback();
  const notificationOccurred = hapticFeedback[1];
  const dispatch = useAppDispatch();
  const coins = useAppSelector((state) => state.profile.user.totalScore);
  const [item, setItem] = useState(booster);
  const [progress, setProgress] = useState(item?.current_income_per_hour || 0);
  const progressRef = useRef(progress);
  const [tooltipTxt, setTooltipTxt] = useState("");

  useEffect(() => {
    if (open) {
      if (!booster) return;
      setItem(booster);
      setProgress(booster?.current_income_per_hour);
    } else {
      setProgress(0);
    }
  }, [open]);

  useEffect(() => {
    progressRef.current = progress;
  }, [progress]);

  const onUpgrade = () => {
    if (!item) return;
    (async () => {
      setTooltipTxt(tooltipTexts.loading);
      try {
        const res = (await dispatch(upgradeBooster(item.card_id))) as {
          error?: unknown;
        };
        if (res.error) throw new Error("Error");
        const interval = setInterval(() => {
          if (progressRef.current < item?.income_per_hour) {
            const incomeSum = Math.round(
              (item?.income_per_hour - item?.current_income_per_hour) / 5
            );
            setProgress(progressRef.current + incomeSum);
          } else {
            setProgress(item?.income_per_hour);
            clearInterval(interval);
            dispatch(updateUserCoins(-item.upgrade_cost));
            dispatch(updateUserCoinsPerHour(item.income_per_hour));
            setTooltipTxt(tooltipTexts.success);
            setTimeout(() => {
              notificationOccurred("success");
              onClose();
              setTooltipTxt("");
            }, 1500);
          }
        }, 200);
      } catch (error) {
        notificationOccurred("success");
        setTooltipTxt("Upgrading failed");
        setTimeout(() => {
          setTooltipTxt("");
        }, 1500);
      }
    })();
  };

  return (
    <>
      <CustomModal open={open} onClose={onClose}>
        <div className={styles.boostBuyModal}>
          <img
            src={`/images/cards/${item?.image_name}`}
            alt="coin"
            className={styles.boostBuyModal__img}
          />
          <h2 className={styles.boostBuyModal__titleTxt}>{item?.name}</h2>
          <p className={styles.boostBuyModal__conentTxt}>{item?.description}</p>
          <div className={styles.boostBuyModal__rewardCoin}>
            <ImageWebp
              src={coinImg}
              srcSet={coinWebpImg}
              alt="coin"
              className={styles.boostBuyModal__rewardCoinImg}
            />
            <span className={styles.boostBuyModal__rewardCoinTxt}>
              +{item?.income_per_hour}/hour
            </span>
          </div>
          <div className={styles.boostBuyModal__progressBlock}>
            <div className={styles.boostBuyModal__progressNumbers}>
              <p className={styles.boostBuyModal__progressNumberTxt}>
                {progress}
              </p>
              <p className={styles.boostBuyModal__progressNumberTxt}>
                {item?.income_per_hour}
              </p>
            </div>
            <ProgressBar
              initialValue={item?.current_income_per_hour}
              value={progress}
              fullValue={item?.income_per_hour || 0}
              showTumb={true}
            />
          </div>
          <MainBtn
            disabled={
              !!tooltipTxt || !item?.upgrade_cost || coins < item?.upgrade_cost
            }
            className={styles.boostBuyModal__buyBtn}
            onClick={onUpgrade}
          >
            <ImageWebp
              src={coinImg}
              srcSet={coinWebpImg}
              alt="coin"
              className={styles.boostBuyModal__btnCoinImg}
            />
            <span>Buy ({item?.upgrade_cost})</span>
          </MainBtn>
        </div>
      </CustomModal>
      <CustomTooltip
        show={!!tooltipTxt}
        className={
          tooltipTxt === tooltipTexts.success
            ? styles.boostBuyModal__tooltipSuccess
            : ""
        }
      >
        {tooltipTxt}
      </CustomTooltip>
    </>
  );
};

export default BoostBuyModal;
