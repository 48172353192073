import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { useTelegram } from "../../hooks/useTelegram";
import {
  basePagePath,
  boostPagePath,
  earnPagePath,
  frensPagePath,
  homePagePath,
  leaguePagePath,
} from "../../router/path";
import styles from "./BottomNavbar.module.scss";

interface Link {
  name: string;
  imgClass: string;
  link: string;
}

interface Props {
  isBottomVisible: boolean;
}

function BottomNavbar({ isBottomVisible }: Props) {
  const location = useLocation();
  const tg = useTelegram();

  const links: Link[] = [
    {
      name: "Moon",
      imgClass: "moon",
      link: homePagePath,
    },
    {
      name: "Frens",
      imgClass: "frens",
      link: frensPagePath,
    },
    {
      name: "Earn",
      imgClass: "earn",
      link: earnPagePath,
    },
    // {
    //     name: "Boost",
    //     imgClass: "boost",
    //     link: durovPagePath
    // },
    {
      name: "Map",
      imgClass: "base",
      link: basePagePath,
    },
  ];

  const isNavLight = location.pathname === leaguePagePath;

  const linkActiveClass = ({ isActive }: { isActive: boolean }) =>
    isActive
      ? `${styles.bottomNavbar__itemLink_active} ${styles.bottomNavbar__itemLink}`
      : `${styles.bottomNavbar__itemLink}`;

  const handleMapClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    tg?.openTelegramLink(basePagePath);
  };

  return (
    <nav
      className={
        `${styles.bottomNavbar} ` +
        `${isNavLight ? styles.bottomNavbar_light : ""} ` +
        `${isBottomVisible ? styles.bottomNavbar_dark : ""}`
      }
    >
      {links.map(({ name, imgClass, link }) => (
        <div className={styles.bottomNavbar__item} key={name}>
          {name === "Map" ? (
            <a
              href={link}
              className={styles.bottomNavbar__itemLink}
              onClick={handleMapClick}
            >
              <div
                className={`${styles.bottomNavbar__itemImg} ${
                  styles[`bottomNavbar__itemImg_${imgClass}`]
                }`}
              />
              <h6 className={styles.bottomNavbar__itemTxt}>{name}</h6>
            </a>
          ) : (
            <NavLink to={link} className={linkActiveClass}>
              <div
                className={`${styles.bottomNavbar__itemImg} ${
                  styles[`bottomNavbar__itemImg_${imgClass}`]
                }`}
              />
              <h6 className={styles.bottomNavbar__itemTxt}>{name}</h6>
            </NavLink>
          )}
          <div className={styles.bottomNavbar__border}></div>
        </div>
      ))}
    </nav>
  );
}

export default BottomNavbar;
