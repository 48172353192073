import { useCallback, useEffect, useRef, useState } from "react";
import uuid from "react-uuid";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import {
  calculateUserCoins,
  saveUpdatesInLocStore,
  swipe,
  toggleIsSwiping,
} from "../../../store/slices/profileSlice";

import HomeTotalScore from "../HomeTotalScore/HomeTotalScore";
import styles from "./HomeRocket.module.scss";

import {
  atomImg,
  bnbImg,
  btcImg,
  dogeImg,
  ethImg,
  fragmentImg,
  ltcImg,
  nearImg,
  shibaImg,
  solImg,
  tonImg,
  usdtImg,
} from "../../../assets/images"; // Обновите путь к вашему файлу импорта

type MouseOrTouchEvent = MouseEvent | TouchEvent;

interface Coin {
  x: number;
  y: number;
  id: string;
  isUpdating: boolean;
  image: string;
}

const METEOR_SIZE = 70;
const METEORS_COUNT_LARGE_SCREEN = 24;
const METEORS_COUNT_SMALL_SCREEN = 12;
const CALCULATE_TIMEOUT = 5000;
const TOUCH_RADIUS = 10;

const getTouchParams = (e: MouseOrTouchEvent) => {
  const y = "clientY" in e ? e.clientY : e.touches[0].clientY;
  const x = "clientX" in e ? e.clientX : e.touches[0].clientX;
  return { x, y };
};

const getCoinRandomPosition = (value: number) => {
  let val = Math.floor(Math.random() * value);
  if (val > METEOR_SIZE) val -= METEOR_SIZE;
  return val;
};

const imageMap: Record<string, string> = {
  atom: atomImg,
  bnb: bnbImg,
  btc: btcImg,
  doge: dogeImg,
  eth: ethImg,
  fragment: fragmentImg,
  ltc: ltcImg,
  near: nearImg,
  shiba: shibaImg,
  sol: solImg,
  usdt: usdtImg,
  ton: tonImg,
};

function HomeRocket() {
  const dispatch = useAppDispatch();
  const energyFinished = useAppSelector((state) => state.profile.energyFinished);
  const isSwiping = useAppSelector((state) => state.profile.isSwiping);
  const onboardingCompleted = useAppSelector((state) => state.profile.user.onboardingCompleted);
  const newAchievmentsChecked = useAppSelector(
    (state) => state.achievments.newAchievmentsChecked
);
  const [coinImages, setCoinImages] = useState<Coin[]>([]);
  const [rewardModalOpened, setRewardModalOpened] = useState(false);
  const [touchPosition, setTouchPosition] = useState<{ x: number, y: number } | null>(null);
  const [meteorsCount, setMeteorsCount] = useState(METEORS_COUNT_LARGE_SCREEN);

  const fireElements = useRef<{ left: string, top: string, id: number }[]>([]);
  const isPressed = useRef(false);
  const isSwipingRef = useRef(isSwiping);
  const energyFinishedRef = useRef(energyFinished);
  const coinImagesRef = useRef(coinImages);
  const lastCoinId = useRef<string>("");
  const calculateTimeOutRef = useRef<NodeJS.Timer | null>(null);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const rewardModalOpenedRef = useRef(rewardModalOpened);
  const onboardingCompletedRef = useRef(onboardingCompleted);
  const newAchievmentsCheckedRef = useRef(newAchievmentsChecked);

  const elementRef = useRef<HTMLDivElement | null>(null);

  const setCoinPositions = () => ({
    x: getCoinRandomPosition(containerRef.current?.clientWidth || 0),
    y: getCoinRandomPosition(containerRef.current?.clientHeight || 0),
  });

  const coinImagesList = Object.keys(imageMap);
  
  const getRandomCoinImage = () => {
    const randomIndex = Math.floor(Math.random() * coinImagesList.length);
    return coinImagesList[randomIndex];
  };

  const initCoins = () => {
    const screenHeight = window.innerHeight;
    const meteorsCount = screenHeight < 400 ? METEORS_COUNT_SMALL_SCREEN : METEORS_COUNT_LARGE_SCREEN;
    setMeteorsCount(meteorsCount);

    const initialCoins: Coin[] = Array.from({ length: meteorsCount }).map(() => ({
      ...setCoinPositions(),
      isUpdating: false,
      id: uuid(),
      image: getRandomCoinImage()
    }));
    setCoinImages(initialCoins);
  };

  useEffect(() => {
    if (coinImages.length && isSwipingRef.current && isPressed.current) dispatch(swipe());
  }, [coinImages, dispatch]);

 
  useEffect(() => {
    coinImagesRef.current = coinImages;
  }, [coinImages]);

  useEffect(() => {
    energyFinishedRef.current = energyFinished;
  }, [energyFinished]);

  useEffect(() => {
    onboardingCompletedRef.current = onboardingCompleted;
  }, [onboardingCompleted]);

  useEffect(() => {
    rewardModalOpenedRef.current = rewardModalOpened;
  }, [rewardModalOpened]);

  useEffect(() => {
    newAchievmentsCheckedRef.current = newAchievmentsChecked;
}, [newAchievmentsChecked]);


  useEffect(() => {
    isSwipingRef.current = isSwiping;
  }, [isSwiping]);

  useEffect(() => {
    if (touchPosition && isPressed.current) {
      spark(touchPosition);
      const { x: curX, y: curY } = touchPosition;
      const curCoin = coinImagesRef.current.find(({ x, y }) => {
        if (!containerRef.current) return false;
        const { x: offsetLeft, y: offsetTop } = containerRef.current?.getBoundingClientRect();
        const offsetX = curX - offsetLeft;
        const offsetY = curY - offsetTop;

        return (
          offsetX > x - TOUCH_RADIUS &&
          offsetX < x + METEOR_SIZE + TOUCH_RADIUS &&
          offsetY > y - TOUCH_RADIUS &&
          offsetY < y + METEOR_SIZE + TOUCH_RADIUS
        );
      });

      if (!curCoin || curCoin.id === lastCoinId.current) return;
      updateCoinImage(curCoin.id);
      lastCoinId.current = curCoin.id;
    }
  }, [touchPosition]);

  const spark = useCallback(({ x, y }: { x: number, y: number }) => {
    const left = `${x}px`;
    const top = `${y}px`;

    const fire = { id: Date.now(), left, top };

    fireElements.current = [...fireElements.current, fire];

    setTimeout(() => {
      fireElements.current = fireElements.current.filter((el) => el.id !== fire.id);
    }, 200);
  }, []);

  const updateCoinImage = (id: string) => {
    setCoinImages((prevState) => [
      ...prevState.filter((item) => item.id !== id),
      {
        id: uuid(),
        isUpdating: false,
        ...setCoinPositions(),
        image: getRandomCoinImage()
      }
    ]);
  };

  const onMouseDown = (e: MouseOrTouchEvent) => {
    if (calculateTimeOutRef.current) {
      clearTimeout(calculateTimeOutRef.current);
      calculateTimeOutRef.current = null;
    }
    if (
      rewardModalOpenedRef.current ||
      !onboardingCompletedRef.current ||
      !newAchievmentsCheckedRef.current
  ) return;

    const el = e.target as Element;
    if (!el.closest("a") && !el.closest("button")) {
      e.preventDefault();
      isPressed.current = true;
      initCoins();
    }
  };

  const onMouseMove = (e: MouseOrTouchEvent) => {
    e.preventDefault();
    if (
      rewardModalOpenedRef.current ||
      !isPressed.current ||
      energyFinishedRef.current ||
      !containerRef.current
    )
      return;

    const { x: curX, y: curY } = getTouchParams(e);
    setTouchPosition({ x: curX, y: curY });
    dispatch(toggleIsSwiping(true));
  };

  const onMouseUp = () => {
    if (!isPressed.current) return;
    isPressed.current = false;
    dispatch(toggleIsSwiping(false));

    setCoinImages([]);
    dispatch(saveUpdatesInLocStore());
    calculateTimeOutRef.current = setTimeout(() => {
      dispatch(calculateUserCoins());
    }, CALCULATE_TIMEOUT);
  };

  useEffect(() => {
    document.body.addEventListener("mousedown", onMouseDown, { passive: false });
    document.body.addEventListener("touchstart", onMouseDown, { passive: false });
    document.body.addEventListener("mousemove", onMouseMove, { passive: false });
    document.body.addEventListener("touchmove", onMouseMove, { passive: false });
    document.body.addEventListener("mouseup", onMouseUp);
    document.body.addEventListener("touchend", onMouseUp);

    return () => {
      document.body.removeEventListener("mousedown", onMouseDown);
      document.body.removeEventListener("touchstart", onMouseDown);
      document.body.removeEventListener("mousemove", onMouseMove);
      document.body.removeEventListener("touchmove", onMouseMove);
      document.body.removeEventListener("mouseup", onMouseUp);
      document.body.removeEventListener("touchend", onMouseUp);
    };
  }, []);


  return (
    <>
      {fireElements.current.map((fire) => (
        <div
          key={fire.id}
          className={`${styles.homeRocket__fire} ${styles.fade}`}
          style={{ left: fire.left, top: fire.top }}
        />
      ))}
      <HomeTotalScore
        modalOpened={rewardModalOpened}
        setModalOpened={setRewardModalOpened}
      />
      <div className={styles.homeRocket}>
        <div className={styles.homeRocket__btn}>
          <div
            ref={elementRef}
            className={`${styles.homeRocket__img} ${energyFinished ? styles.homeRocket__img_disabled : ""}`}
          />
        </div>
        <div className={styles.homeRocket__swipeArea} ref={containerRef}>
          {coinImages.map(({ x, y, id, isUpdating, image }) => (
            <div
              key={id}
              style={{
                top: y,
                left: x,
                width: METEOR_SIZE,
                height: METEOR_SIZE,
                backgroundImage: `url(${imageMap[image]})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat'
              }}
              className={`${styles.homeRocket__coinImage} ${isUpdating ? styles.homeRocket__coinImage_fall : ""}`}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default HomeRocket;
