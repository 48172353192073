import React, { useEffect } from 'react'

import styles from "./BoostWrapper.module.scss"
import BoostHeader from '../BoostHeader/BoostHeader'
import BoostList from '../BoostList/BoostList'
import {useTelegram} from "../../../hooks/useTelegram";
import {useNavigate} from "react-router-dom";

type Props = {}

const BoostWrapper = (props: Props) => {
  const tg = useTelegram()
  const navigate = useNavigate()

  useEffect(() => {
    tg?.BackButton.show();
    tg?.BackButton.onClick(() => navigate(-1))
    return () => {
        tg?.BackButton.hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  return (
    <div className={styles.boostWrapper}>
        <BoostHeader/>
        <BoostList/>
    </div>
  )
}

export default BoostWrapper