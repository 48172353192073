import React, { FC, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { leaguePagePath } from "../../../router/path";
import {
  claimRewards,
  updateUserCoins,
} from "../../../store/slices/profileSlice";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { Link } from "react-router-dom";

import CustomModal from "../../layout/CustomModal/CustomModal";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import MainBtn from "../../layout/MainBtn/MainBtn";

import { formatNumber } from "../../../helpers/formatNumber";
import {
  arrowIconImg,
  coinImg,
  coinWebpImg,
  rewardPixelIconImg,
  rewardPixelIconWebpImg,
} from "../../../assets/images";
import styles from "./HomeTotalScore.module.scss";
import { useTranslation } from "react-i18next";
import { useTelegram } from "../../../hooks/useTelegram";

interface HomeTotalScoreProps {
  modalOpened: boolean;
  setModalOpened: (val: boolean) => void;
}

const HomeTotalScore: FC<HomeTotalScoreProps> = ({
  modalOpened,
  setModalOpened,
}) => {
  const dispatch = useAppDispatch();
  const hapticFeedback = useHapticFeedback();
  const notificationOccurred = hapticFeedback[1];
  const { t } = useTranslation();

  const totalScore = useAppSelector((state) => state.profile.user.totalScore);
  const newAchievmentsChecked = useAppSelector(
    (state) => state.achievments.newAchievmentsChecked
  );
  const is_subscribed = useAppSelector(
    (state) => state.profile.user.is_subscribed
  );
  const passiveIncomeEarned = useAppSelector(
    (state) => state.profile.user.passiveIncomeEarned
  );
  const passiveIncome = useAppSelector(
    (state) => state.profile.user.passiveIncome
  );
  const getUserLoading = useAppSelector(
    (state) => state.profile.getUserLoading
  );
  const onboardingCompleted = useAppSelector(
    (state) => state.profile.user.onboardingCompleted
  );
  const [score, setScore] = useState(0);
  const [isSubscribedLocal, setIsSubscribedLocal] = useState(is_subscribed);
  const dailyCheckinChecked = useAppSelector(
    (state) => state.profile.dailyCheckinChecked
  );

  const scoreRef = useRef(score);
  const passiveIncomeEarnedRef = useRef(passiveIncomeEarned);
  const isClaimed = useRef(false);

  const tg = useTelegram();

  useEffect(() => {
    // Обновляем локальное состояние, когда получаем данные из API
    setIsSubscribedLocal(is_subscribed);
  }, [is_subscribed]);
  console.log({ passiveIncome, newAchievmentsChecked });

  useEffect(() => {
    if (
      !getUserLoading &&
      passiveIncome &&
      passiveIncomeEarned &&
      onboardingCompleted &&
      newAchievmentsChecked
    ) {
      setModalOpened(true);
      setScore(totalScore);
      passiveIncomeEarnedRef.current = passiveIncomeEarned;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserLoading, onboardingCompleted, newAchievmentsChecked]);

  useEffect(() => {
    if (!modalOpened) setScore(totalScore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalScore]);

  useEffect(() => {
    scoreRef.current = score;
  }, [score]);

  const onClose = () => {
    setModalOpened(false);
  };

  const onSubscribe = () => {
    // Логика подписки
    tg?.openTelegramLink("https://t.me/+y9of5UsVg_NkM2Fi");
    setTimeout(() => {
      setIsSubscribedLocal(true); // Обновляем локальное состояние с задержкой в 1 секунду
    }, 1000);
  };

  const onClaim = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) e.stopPropagation();
    dispatch(claimRewards());
    notificationOccurred("success");
    onClose();
    const updatedTotalScore = totalScore + passiveIncomeEarnedRef.current;
    const interval = setInterval(() => {
      if (scoreRef.current >= updatedTotalScore) {
        dispatch(updateUserCoins(passiveIncomeEarnedRef.current));
        isClaimed.current = true;
        clearInterval(interval);
      } else {
        setScore((prevState) =>
          prevState + 20 > updatedTotalScore
            ? updatedTotalScore
            : prevState + 50
        );
      }
    }, 50);
  };

  return (
    <>
      <div className={styles.homeTotalScore}>
        <ImageWebp
          srcSet={coinWebpImg}
          src={coinImg}
          alt={"coin"}
          className={styles.homeTotalScore__coinIcon}
        />
        <Link
          to={leaguePagePath}
          className={`${styles.homeTotalScore__txt} ${
            totalScore.toString().length > 7
              ? styles.homeTotalScore__txt_small
              : ""
          }`}
        >
          {formatNumber(score)}
        </Link>
        <ImageWebp
          src={arrowIconImg}
          srcSet={arrowIconImg}
          alt={"icon"}
          className={styles.homeTotalScore__arrowIcon}
        />
      </div>

      <CustomModal
        open={modalOpened && dailyCheckinChecked}
        onClose={onClose}
        containerClass={styles.homeTotalScore__modal}
      >
        <div className={styles.homeTotalScore__modalMain}>
          <ImageWebp
            src={rewardPixelIconImg}
            srcSet={rewardPixelIconWebpImg}
            alt="pixel"
            className={styles.homeTotalScore__modalIcon}
          />
          <div className={styles.homeTotalScore__modalCoins}>
            <ImageWebp
              srcSet={coinWebpImg}
              src={coinImg}
              alt={"coin"}
              className={styles.homeTotalScore__modalCoinImg}
            />
            <h5 className={styles.homeTotalScore__modalCoinText}>
              {passiveIncomeEarned}
            </h5>
          </div>
          <h3 className={styles.homeTotalScore__modalMainText}>
            {t("messages.incomeMessage")}
          </h3>
        </div>
        <MainBtn onClick={isSubscribedLocal ? onClaim : onSubscribe}>
          {isSubscribedLocal ? t("buttons.claim") : t("buttons.subscribe")}
        </MainBtn>
      </CustomModal>
    </>
  );
};

export default HomeTotalScore;
