import { useState } from "react";
import {
  blockImage,
  blockWebpImage,
  coinDisableImage,
  coinDisableWebpImage,
  coinImg,
  coinWebpImg,
} from "../../../assets/images";
import { formatNumberShort } from "../../../helpers/formatNumberShort";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import { useLocation } from "react-router-dom";

import {
  BoostCategories,
  categories,
} from "../../../constants/BoostCategories";
import { useAppSelector } from "../../../hooks/redux";
import BoostBuyModal from "./BoostBuyModal/BoostBuyModal";
import styles from "./BoostList.module.scss";

const BoostList = () => {
  const location = useLocation();
const [curCategory, setCurCategory] = useState<BoostCategories>(
  location.state?.selectedCategory || categories[0]
);
  const [upgradingBoostId, setUpgradingBoostId] = useState<number | null>(null);
  const data = useAppSelector((state) => state.boost.boosters);
  const upgradingBoost = data
    ? data?.find((item) => item.card_id === upgradingBoostId)
    : undefined;
  

  return (
    <div className={styles.boostList}>
      <div className={styles.boostList__categories}>
        <div className={styles.boostList__categoriesList}>
          {categories.map((item) => (
            <button
              onClick={() => setCurCategory(item)}
              className={`${styles.boostList__categoryItem} ${
                curCategory === item
                  ? styles.boostList__categoryItem_active
                  : ""
              }`}
              key={item}
            >
              <div
                className={`${styles.boostList__categoryItemInner} ${
                  curCategory === item
                    ? styles.boostList__categoryItemInner_active
                    : ""
                }`}
              >
                {item}
              </div>
            </button>
          ))}
        </div>
      </div>
      <div className={styles.boostList__main}>
        {data &&
          data
            .filter((item) => item.category === curCategory)
            .map((item, index) => (
              <button
                disabled={item.blocked}
                onClick={() => setUpgradingBoostId(item.card_id)}
                className={`${styles.boostList__item} ${
                  item.blocked ? styles.boostList__item_blocked : ""
                }`}
                key={index}
              >
                <div className={styles.boostList__itemMain}>
                  <div className={styles.boostList__itemMainWrapper}>
                    <div className={styles.boostList__itemImgWrapper}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cards/${item.image_name}`}
                        alt="avatar"
                        className={styles.boostList__itemImg}
                      />

                      {item.blocked && (
                        <div className={styles.boostList__itemImgBlockOverlay}>
                          <ImageWebp
                            src={blockImage}
                            srcSet={blockWebpImage}
                            alt="block"
                            className={styles.boostList__itemBlockImg}
                          />
                        </div>
                      )}
                    </div>
                    <div className={styles.boostList__itemTexts}>
                      <h6 className={styles.boostList__nameTxt}>{item.name}</h6>
                      <p className={styles.boostList__descriptionTxt}>
                        Earnings per hour
                      </p>
                      <div className={styles.boostList__perHoursCoins}>
                        <ImageWebp
                          src={!item.blocked ? coinImg : coinDisableImage}
                          srcSet={
                            !item.blocked ? coinWebpImg : coinDisableWebpImage
                          }
                          alt="coin"
                          className={styles.boostList__perHoursCoinImg}
                        />
                        <span className={styles.boostList__perHoursCoinsTxt}>
                          {formatNumberShort(item.income_per_hour)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.boostList__itemFooter}>
                  <div className={styles.boostList__lvlBlock}>
                    <p className={styles.boostList__lvlTxt}>lvl {item.level}</p>
                  </div>
                  {!item.blocked ? (
                    <div className={styles.boostList__priceCoins}>
                      <ImageWebp
                        src={coinImg}
                        srcSet={coinWebpImg}
                        alt="coin"
                        className={styles.boostList__priceCoinImg}
                      />
                      <span className={styles.boostList__priceCoinTxt}>
                        {formatNumberShort(item.upgrade_cost)}
                      </span>
                    </div>
                  ) : (
                    <p className={styles.boostList__blockTxt}>
                      {item?.unlock_required || ""}
                    </p>
                  )}
                </div>
              </button>
            ))}
      </div>
      <BoostBuyModal
        open={!!upgradingBoostId}
        onClose={() => setUpgradingBoostId(null)}
        booster={upgradingBoost}
      />
    </div>
  );
};

export default BoostList;
