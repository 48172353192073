import { FC } from "react";

import styles from "./ProgressBar.module.scss";

interface ProgressBarProps {
  value: number;
  fullValue: number;
  initialValue?: number;
  showTumb?: boolean;
}

const ProgressBar: FC<ProgressBarProps> = ({
  value,
  fullValue,
  showTumb,
  initialValue,
}) => {
  const initial = initialValue || 0; 
  let energyPercent = ((value - initial) * 100) / (fullValue - initial);
  if (energyPercent > 100) energyPercent = 100;
  return (
    <div className={styles.progressbar}>
      <div
        style={{ width: `${energyPercent}%` }}
        className={styles.progressbar__inner}
      >
        <div className={styles.progressbar__gradient}></div>
      </div>
      {showTumb && (
        <div
          style={{ left: `${energyPercent}%` }}
          className={styles.progressbar__thumb}
        ></div>
      )}
    </div>
  );
};

export default ProgressBar;
