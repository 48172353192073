import React, { FC } from 'react';
import TransitionProvider, { TransitionStyleTypes } from "../../providers/TransitionProvider";

import styles from "./Loader.module.scss";
import ImageWebp from "../layout/ImageWebp/ImageWebp";
import { rocketLoaderImg, rocketLoaderWebpImg } from "../../assets/images";
import { useAppSelector } from "../../hooks/redux";

interface LoaderProps {
    imagesLoading: boolean;
}

const Loader: FC<LoaderProps> = ({ imagesLoading }) => {
    const getLoading = useAppSelector(state => state.profile.getUserLoading);

    const stopMouseEvent: React.MouseEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const stopTouchEvent: React.TouchEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <TransitionProvider
            type={TransitionStyleTypes.opacityLeave}
            inProp={getLoading || imagesLoading}
            className={styles.loader}
            onMouseDown={stopMouseEvent}
            onMouseMove={stopMouseEvent}
            onTouchStart={stopTouchEvent}
            onTouchMove={stopTouchEvent}
        >
            <ImageWebp
                src={rocketLoaderImg}
                srcSet={rocketLoaderWebpImg}
                alt="rocket"
                className={styles.fullscreenImage}
            />
        </TransitionProvider>
    );
};

export default Loader;
