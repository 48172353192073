export enum BoostCategories {
  Skills = "Skills",
  Telegram = "Telegram",
  TON = "TON",
  Lounge = "Lounge",
}

export const categories = [
  BoostCategories.Skills,
  BoostCategories.Telegram,
  BoostCategories.TON,
  BoostCategories.Lounge,
];
