import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from 'react-i18next';

import { store } from "./store/store";
import { setBackgroundAsSecondary } from './helpers/setBackgroundAsSecondary';
import { App } from './components/App/App';
import i18n from './i18n'; // Импортируйте ваш файл конфигурации i18n

import '@telegram-apps/telegram-ui/dist/styles.css';
import './styles/_style.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";

setBackgroundAsSecondary();

const root = createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store()}>
        <BrowserRouter>
            <I18nextProvider i18n={i18n}>
                <App />
            </I18nextProvider>
        </BrowserRouter>
    </Provider>
);
