import { createSlice } from "@reduxjs/toolkit";

import { Achievments } from "../../models/IAchievment";

export interface AchievmentsState {
  achievments: Achievments | null;
  newAchievments: number[];
  newAchievmentsChecked: boolean
}

const initialState: AchievmentsState = {
  achievments: null,
  newAchievments: [],
  newAchievmentsChecked: false
};

export const achievmentsSlice = createSlice({
  name: "achievmentsSlice",
  initialState,
  reducers: {
    setAchievments(state, { payload }) {
      state.achievments = payload;
    },
    showNewAchievments(state, { payload }) {
      state.newAchievments = payload;
    },
    hideNewAchievments(state) {
      state.newAchievments = [];
      state.newAchievmentsChecked = true
    },
  },
  extraReducers: (builder) => {},
});

export const { setAchievments, showNewAchievments, hideNewAchievments } =
  achievmentsSlice.actions;

export default achievmentsSlice.reducer;
