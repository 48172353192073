import { FC } from "react";

import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import styles from "./AchievmentImageFrame.module.scss";

interface AchievmentImageFrameProps {
  src: string;
  srcSet: string;
}

const AchievmentImageFrame: FC<AchievmentImageFrameProps> = ({
  src,
  srcSet,
}) => {
  return (
    <div className={styles.achievmentImageFrame}>
      <div className={styles.achievmentImageFrame__inner}>
        <ImageWebp
          srcSet={srcSet}
          src={src}
          alt="achievment"
          className={styles.achievmentImageFrame__img}
        />
      </div>
    </div>
  );
};

export default AchievmentImageFrame;
