import React, {FC, ReactNode} from "react";
import {animated, useTransition} from 'react-spring'

export enum TransitionStyleTypes {
    opacity = "opacity",
    opacityLeave = "opacityLeave",
    rotateX = "rotateX",
    right = "right",
    translateX = "translateX",
    height = "height",
    top = "top",
    bottom = "bottom",
}

interface TransitionProviderProps extends React.HTMLAttributes<HTMLDivElement> {
    type: TransitionStyleTypes,
    inProp: boolean,
    className?: string,
    duration?: number,
    height?: number,
    children: ReactNode
}

const TransitionProvider: FC<TransitionProviderProps> = ({
                                                             type,
                                                             inProp,
                                                             className,
                                                             duration,
                                                             height,
                                                             children,
                                                             ...args
                                                         }) => {
    const transDuration = duration ? duration : 300;


    const styles = {
      [TransitionStyleTypes.opacity]: {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
      },
      [TransitionStyleTypes.opacityLeave]: {
        from: { opacity: 1 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
      },
      [TransitionStyleTypes.rotateX]: {
        from: { transform: "rotateX(90deg)" },
        enter: { transform: "rotateX(0deg)" },
        leave: { transform: "rotateX(90deg)" },
      },

      [TransitionStyleTypes.right]: {
        from: { right: "-630px" },
        enter: { right: "0" },
        leave: { right: "-630px" },
      },
      [TransitionStyleTypes.translateX]: {
        from: { translateX: "100%" },
        enter: { translateX: "0%" },
        leave: { translateX: "100%" },
      },
      [TransitionStyleTypes.top]: {
        from: { translateY: "-200px" },
        enter: { translateY: "0px" },
        leave: { translateY: "-200px" },
      },
      [TransitionStyleTypes.bottom]: {
        from: { translateY: "200px" },
        enter: { translateY: "0px" },
        leave: { translateY: "200px" },
      },
      [TransitionStyleTypes.height]: {
        from: { height: "0px" },
        enter: { height: `${height || 10}px` },
        leave: { height: "0px" },
      },
    };

    const transition = useTransition(inProp, {...styles[type], config: {duration: transDuration}})


    return (
        <>
            {transition((style, item) => item ?
                <animated.div
                    {...args}
                    style={style}
                    className={className ? className : ''}
                >{children}</animated.div> : '')}
        </>
    )
}

export default TransitionProvider