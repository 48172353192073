import {combineReducers, configureStore} from "@reduxjs/toolkit";
import ProfileReducer from "./slices/profileSlice";
import TasksReducer from "./slices/tasksSlice";
import FrensReducer from "./slices/frensSlice";
import LeaguesReducer from "./slices/leaguesSlice";
import BoostReducer from "./slices/boostSlice";
import AchievmentsReducer from "./slices/achievmentsSlice";

const rootReducer = combineReducers({
    profile: ProfileReducer,
    tasks: TasksReducer,
    frens: FrensReducer,
    boost: BoostReducer,
    leagues: LeaguesReducer,
    achievments: AchievmentsReducer,
})

export const store = () => {
    return configureStore({
        reducer: rootReducer
    })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof store>
export type AppDispatch = AppStore['dispatch']