import React, {ReactElement} from "react";

import HomePage from "../pages/HomePage";
import SettingsPage from "../pages/SettingsPage";
import FrensPage from "../pages/FrensPage";
import EarnPage from "../pages/EarnPage";
import BasePage from "../pages/BasePage";
import LeaguePage from "../pages/LeaguePage";
import BoostPage from "../pages/BoostPage";
import DurovPage from "../pages/DurovPage";
import AchievmentsPage from "../pages/AchievmentsPage";

export const homePagePath = '/'
export const durovPagePath = '/durov'
export const boostPagePath = '/boost'
export const frensPagePath = '/frens'
export const earnPagePath = '/earn'
// export const basePagePath = '/base'
export const basePagePath = 'https://t.me/thetonmapbot/Map'
export const settingsPagePath = '/settings'
export const leaguePagePath = '/league'
export const achievmentsPagePath = "/achievments";

interface IRoute {
    path: string,
    component: ReactElement<any, any>,
    children?: Omit<IRoute, "children">[]
}

export const routes:IRoute[] = [
    {
        path: homePagePath,
        component: <HomePage />
    },
    {
        path: durovPagePath,
        component: <DurovPage />
    },
    {
        path: boostPagePath,
        component: <BoostPage />
    },
    {
        path: frensPagePath,
        component: <FrensPage />
    },
    {
        path: earnPagePath,
        component: <EarnPage />
    },
    {
        path: basePagePath,
        component: <BasePage />
    },
    {
        path: leaguePagePath,
        component: <LeaguePage />
    },

    {
        path: settingsPagePath,
        component: <SettingsPage/>
    },
    {
        path: achievmentsPagePath,
        component: <AchievmentsPage />,
      },

]