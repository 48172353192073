import React, {FC, HTMLAttributes, ReactNode} from 'react';

import styles from "./MainBtn.module.scss"

interface MainBtnProps  extends HTMLAttributes<HTMLButtonElement> {
    children: ReactNode,
    className?: string,
    disabled?: boolean,
}

const MainBtn:FC<MainBtnProps> = ({children,className,disabled,...attrs}) => {
    return (
      <button
        {...attrs}
        disabled={disabled}
        className={`${styles.mainBtn} ${className || ""}`}
      >
        {children}
      </button>
    );
}

export default MainBtn;