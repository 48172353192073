import React from 'react';
import {AppRoot, List} from "@telegram-apps/telegram-ui";

import {CellSection} from "../components/SettingsPage/CellSection";
import {FormSection} from "../components/SettingsPage/FormSection/FormSection";
import {BannerSection} from "../components/SettingsPage/BannerSection";
import {TimelineSection} from "../components/SettingsPage/TimelineSection/TimelineSection";
import {TooltipSection} from "../components/SettingsPage/TooltipSection/TooltipSection";
import {ModalSection} from "../components/SettingsPage/ModalSection/ModalSection";

function SettingsPage() {
    return (
        <AppRoot>
            <List>
                <CellSection />
                <FormSection />
                <BannerSection />
                <TimelineSection />
                <TooltipSection />
                <ModalSection />
            </List>
        </AppRoot>
    );
}

export default SettingsPage;