import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { GetBoostersRequest } from "../../models/api/boost/getBoosters";
import { UpgradeBoosterResponse } from "../../models/api/boost/upgradeBooster";
import { IBooster } from "../../models/IBooster";
import { RootState } from "../store";
import {
  fetchRequest,
  getBoostersUrl,
  upgradeBoosterUrl,
} from "../tools/fetchTools";

export interface BoostState {
  boosters: IBooster[];
}

const initialState: BoostState = {
  boosters: [],
};

export const getBoosters = createAsyncThunk<
  IBooster[],
  GetBoostersRequest,
  { rejectValue: string }
>("boost/getBoosters", async (payload, { rejectWithValue }) => {
  try {
    const resData = await fetchRequest<IBooster[]>(
      getBoostersUrl,
      "POST",
      payload
    );

    return resData;
  } catch (error) {
    return rejectWithValue("Error");
  }
});

export const upgradeBooster = createAsyncThunk<
  IBooster[],
  number,
  { rejectValue: string }
>("boost/upgradeBoosters", async (id, { rejectWithValue, getState }) => {
  try {
    const state = getState() as RootState;
    const resData = await fetchRequest<UpgradeBoosterResponse>(
      upgradeBoosterUrl,
      "POST",
      {
        user_id: state.profile.user.userId,
        card_id: id,
      }
    );
    let boosters = [...state.boost.boosters];
    const curBoosterIndex = boosters.findIndex((item) => item.card_id === id);

    if(resData.unlocked.length) {
      boosters = boosters.map((item) => ({
        ...item,
        blocked: resData.unlocked.includes(item.card_id) ? false : item.blocked,
      }));
    }

    if (curBoosterIndex !== -1) {
      boosters[curBoosterIndex] = {
        ...boosters[curBoosterIndex],
        level: resData.next_level,
        current_income_per_hour: boosters[curBoosterIndex].income_per_hour,
        income_per_hour: resData.next_income_per_hour,
        upgrade_cost: resData.next_upgrade_cost,
      };
    }

    return boosters;
  } catch (error) {
    throw new Error("error");
  }
});

export const boostSlice = createSlice({
  name: "boostSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBoosters.fulfilled, (state, { payload }) => {
      state.boosters = payload;
    });
    builder.addCase(upgradeBooster.fulfilled, (state, { payload }) => {
      state.boosters = payload;
    });
  },
});

export default boostSlice.reducer;
