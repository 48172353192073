import React from 'react'

import styles from "./DurovWrapper.module.scss"
import BoostHeader from '../DurovHeader/DurovHeader'
import BoostList from '../DurovList/DurovList'

type Props = {}

const BoostWrapper = (props: Props) => {
  return (
    <div className={styles.boostWrapper}>
        {/* <BoostHeader/>
        <BoostList/> */}
    </div>
  )
}

export default BoostWrapper