import { ReactNode, useRef } from "react";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";

import styles from "./CustomTooltip.module.scss"

type Props = {
  children: ReactNode;
  show: boolean;
  className?: string;
};

function CustomTooltip({ children, show, className }: Props) {
  return (
    <TransitionProvider
      inProp={show}
      type={TransitionStyleTypes.top}
      className={`${styles.customTooltip} ${className || ""}`}
    >
      <p className={styles.customTooltip__txt}>{children}</p>
    </TransitionProvider>
  );
}

export default CustomTooltip;
