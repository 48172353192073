import {
  achievmentCards1Image,
  achievmentCards1WebpImage,
  achievmentCardsDisabledImage,
  achievmentCardsDisabledWebpImage,
  achievmentCoins1Image,
  achievmentCoins1WebpImage,
  achievmentCoinsDisabledImage,
  achievmentCoinsDisabledWebpImage,
  achievmentIncome1Image,
  achievmentIncome1WebpImage,
  achievmentIncomeDisabledImage,
  achievmentIncomeDisabledWebpImage,
  achievmentLand1Image,
  achievmentLand1WebpImage,
  achievmentLandDisabledImage,
  achievmentLandDisabledWebpImage,
  achievmentStream1Image,
  achievmentStream1WebpImage,
  achievmentStreamDisabledImage,
  achievmentStreamDisabledWebpImage,
  achievmentVideo1Image,
  achievmentVideo1WebpImage,
  achievmentVideoDisabledImage,
  achievmentVideoDisabledWebpImage,
  achievmentCheckin1Image,
  achievmentCheckin1WebpImage,
  achievmentCheckinDisabledImage,
  achievmentCheckinDisabledWebpImage

} from "../assets/images";

export enum AchievmentKeys {
  AdsWatched = "AdsWatched",
  CardsUpgraded = "CardsUpgraded",
  CoinsEarned = "CoinsEarned",
  GenesisUser = "GenesisUser",
  PassiveIncomeEarned = "PassiveIncomeEarned",
  PixelsOwned = "PixelsOwned",
  CheckinsCompleted = "DailyCheckIn",

}

export const achievmentImages: {
  [key in string]: { src: string; srcSet: string }[];
} = {
  [AchievmentKeys.AdsWatched]: [
    {
      src: achievmentVideo1Image,
      srcSet: achievmentVideo1WebpImage,
    },
    {
      src: achievmentVideoDisabledImage,
      srcSet: achievmentVideoDisabledWebpImage,
    },
  ],
  [AchievmentKeys.CardsUpgraded]: [
    {
      src: achievmentCards1Image,
      srcSet: achievmentCards1WebpImage,
    },
    {
      src: achievmentCardsDisabledImage,
      srcSet: achievmentCardsDisabledWebpImage,
    },
  ],
  [AchievmentKeys.CoinsEarned]: [
    {
      src: achievmentCoins1Image,
      srcSet: achievmentCoins1WebpImage,
    },
    {
      src: achievmentCoinsDisabledImage,
      srcSet: achievmentCoinsDisabledWebpImage,
    },
  ],
  [AchievmentKeys.GenesisUser]: [
    {
      src: achievmentStream1Image,
      srcSet: achievmentStream1WebpImage,
    },
    {
      src: achievmentStreamDisabledImage,
      srcSet: achievmentStreamDisabledWebpImage,
    },
  ],
  [AchievmentKeys.PassiveIncomeEarned]: [
    {
      src: achievmentIncome1Image,
      srcSet: achievmentIncome1WebpImage,
    },
    {
      src: achievmentIncomeDisabledImage,
      srcSet: achievmentIncomeDisabledWebpImage,
    },
  ],
  [AchievmentKeys.PixelsOwned]: [
    {
      src: achievmentLand1Image,
      srcSet: achievmentLand1WebpImage,
    },
    {
      src: achievmentLandDisabledImage,
      srcSet: achievmentLandDisabledWebpImage,
    },
  ],
  [AchievmentKeys.CheckinsCompleted]: [
    {
      src: achievmentCheckin1Image,
      srcSet: achievmentCheckin1WebpImage,
    },
    {
      src: achievmentCheckinDisabledImage,
      srcSet: achievmentCheckinDisabledWebpImage,
    },
  ],
};
